<template>
  <div class="cut-base" @wheel.prevent="doNothing">
    <div class="close el-icon-circle-close" @click="onclose"></div>
    <div
      :style="{ width: baseWidth + 'px', height: baseHeight + 'px' }"
      class="cutRange"
    >
      <canvas
        class="cut-canvas"
        ref="cutboard"
        :width="baseWidth"
        :height="baseHeight"
        @mousedown="canvasMouseDown($event)"
        @mousemove="canvasMouseMove($event)"
        @mouseleave="canvasMouseEnd()"
        @mouseup="canvasMouseEnd()"
        @wheel.prevent="canvasWheel($event)"
      />
    </div>
    <div class="buttons">
      <el-button type="danger" @click="done()">确定</el-button>
    </div>
  </div>
</template>

<script>
var baseWidth;
var baseHeight;
var imageHeight;
var imageWidth;
var headX;
var headY;
var headRadio;
var lastDownX;
var lastDownY;
var lastDownHeadX;
var lastDownHeadY;
var pressed = 0;

export default {
  name: "Wm",
  props: ["index", "param", "type", "imgInfo"],
  components: {},
  data() {
    return {
      baseWidth: 0,
      baseHeight: 0,
      baseSrc: "",
      canvas: "",
      image: undefined,
      closeflag: 1
    };
  },
  created() {
    console.log("cut created", this.imgInfo);
    baseWidth = parseInt(this.imgInfo.width);
    baseHeight = parseInt(this.imgInfo.height);
    this.baseWidth = parseInt(this.imgInfo.width);
    this.baseHeight = parseInt(this.imgInfo.height);
    this.baseSrc = this.imgInfo.src;
    this.image = new Image();
    this.image.onload = () => {
      imageHeight = this.image.height;
      imageWidth = this.image.width;
      this.init();
    };
    this.image.src = this.baseSrc;
  },
  mounted() {
    this.canvas = this.$refs.cutboard;
  },
  methods: {
    doNothing() {},
    done() {
      var centerReactSide =
        baseWidth > baseHeight ? baseHeight * 0.75 : baseWidth * 0.75;
      var cutX1 = (baseWidth - centerReactSide) / 2;
      var cutX2 = (baseWidth + centerReactSide) / 2;
      var cutY1 = (baseHeight - centerReactSide) / 2;
      var cutY2 = (baseHeight + centerReactSide) / 2;
      var finalX1 =
        (cutX1 - (headX - (headRadio * imageWidth) / 2)) /
        (headRadio * imageWidth);
      var finalX2 =
        (cutX2 - (headX - (headRadio * imageWidth) / 2)) /
        (headRadio * imageWidth);
      console.log(headY - (headRadio * imageHeight) / 2 - cutY1);
      console.log(headRadio * imageHeight);
      var finalY1 =
        (cutY1 - (headY - (headRadio * imageHeight) / 2)) /
        (headRadio * imageHeight);
      var finalY2 =
        (cutY2 - (headY - (headRadio * imageHeight) / 2)) /
        (headRadio * imageHeight);

      this.closeflag = 2;
      var arrs = {};
      arrs.x1 = finalX1;
      arrs.x2 = finalX2;
      arrs.y1 = finalY1;
      arrs.y2 = finalY2;

      this.$emit("editFinished", this.closeflag, arrs);
    },
    canvasWheel(event) {
      var newHeadRadio = headRadio;
      if (event.deltaY > 0) {
        // smaller
        newHeadRadio -= 0.01;
      } else if (event.deltaY < 0) {
        // bigger
        newHeadRadio += 0.01;
      }
      var centerReactSide =
        baseWidth > baseHeight ? baseHeight * 0.75 : baseWidth * 0.75;
      var cutX1 = (baseWidth - centerReactSide) / 2;
      var cutX2 = (baseWidth + centerReactSide) / 2;
      var cutY1 = (baseHeight - centerReactSide) / 2;
      var cutY2 = (baseHeight + centerReactSide) / 2;
      var newHeadX = headX;
      var newHeadY = headY;
      if (newHeadX - (newHeadRadio * imageWidth) / 2 >= cutX1) {
        if (newHeadRadio * imageWidth > cutX2 - cutX1) {
          newHeadX -= headX - (newHeadRadio * imageWidth) / 2 - cutX1;
        } else {
          return;
        }
      }
      if (newHeadX + (newHeadRadio * imageWidth) / 2 <= cutX2) {
        if (newHeadRadio * imageWidth > cutX2 - cutX1) {
          newHeadX += cutX2 - (headX + (newHeadRadio * imageWidth) / 2);
        } else {
          return;
        }
      }
      if (headY - (newHeadRadio * imageHeight) / 2 >= cutY1) {
        if (newHeadRadio * imageHeight > cutY2 - cutY1) {
          newHeadY -= headY - (newHeadRadio * imageHeight) / 2 - cutY1;
        } else {
          return;
        }
      }
      if (headY + (newHeadRadio * imageHeight) / 2 <= cutY2) {
        if (newHeadRadio * imageHeight > cutY2 - cutY1) {
          newHeadY += cutY2 - (headY + (newHeadRadio * imageHeight) / 2);
        } else {
          return;
        }
      }
      headRadio = newHeadRadio;
      headX = newHeadX;
      headY = newHeadY;
      this.drawAll();
    },
    canvasMouseEnd() {
      pressed = 0;
      this.drawAll();
    },
    canvasMouseDown(event) {
      var x = event.layerX || event.offsetX;
      var y = event.layerY || event.offsetY;
      pressed = 1;
      lastDownX = x;
      lastDownY = y;
      lastDownHeadX = headX;
      lastDownHeadY = headY;
    },
    canvasMouseMove(event) {
      if (pressed != 1) {
        return;
      }
      var x = event.layerX || event.offsetX;
      var y = event.layerY || event.offsetY;
      var relativeX = x - lastDownX;
      var relativeY = y - lastDownY;
      var newHeadX = lastDownHeadX + relativeX;
      var newHeadY = lastDownHeadY + relativeY;
      var centerReactSide =
        baseWidth > baseHeight ? baseHeight * 0.75 : baseWidth * 0.75;
      var cutX1 = (baseWidth - centerReactSide) / 2;
      var cutX2 = (baseWidth + centerReactSide) / 2;
      var cutY1 = (baseHeight - centerReactSide) / 2;
      var cutY2 = (baseHeight + centerReactSide) / 2;
      if (newHeadX - (headRadio * imageWidth) / 2 >= cutX1) {
        newHeadX = cutX1 + (headRadio * imageWidth) / 2;
      }
      if (newHeadX + (headRadio * imageWidth) / 2 <= cutX2) {
        newHeadX = cutX2 - (headRadio * imageWidth) / 2;
      }
      if (newHeadY - (headRadio * imageHeight) / 2 >= cutY1) {
        newHeadY = cutY1 + (headRadio * imageHeight) / 2;
      }
      if (newHeadY + (headRadio * imageHeight) / 2 <= cutY2) {
        newHeadY = cutY2 - (headRadio * imageHeight) / 2;
      }
      headX = newHeadX;
      headY = newHeadY;
      this.drawAll();
    },

    init() {
      var centerReactSide =
        baseWidth > baseHeight ? baseHeight * 0.75 : baseWidth * 0.75;
      // cutX1 = (baseWidth - centerReactSide) / 2;
      // cutX2 = (baseWidth + centerReactSide) / 2;
      // cutY1 = (baseHeight - centerReactSide) / 2;
      // cutY2 = (baseHeight + centerReactSide) / 2;
      headX = baseWidth * 0.5;
      headY = baseHeight * 0.5;
      headRadio =
        centerReactSide / (imageWidth > imageHeight ? imageHeight : imageWidth);
      this.drawAll();
    },
    drawAll() {
      var centerReactSide =
        baseWidth > baseHeight ? baseHeight * 0.75 : baseWidth * 0.75;
      var ctx = this.canvas.getContext("2d");
      ctx.fillStyle = "#000000";
      ctx.fillRect(0, 0, baseWidth, baseHeight);
      ctx.globalAlpha = 1;
      ctx.drawImage(
        this.image,
        0,
        0,
        imageWidth,
        imageHeight,
        headX - (imageWidth * headRadio) / 2,
        headY - (imageHeight * headRadio) / 2,
        imageWidth * headRadio,
        imageHeight * headRadio
      );
      //draw cover
      var outSize = baseWidth > baseHeight ? baseWidth : baseHeight;
      ctx.beginPath();
      ctx.arc(
        baseWidth / 2,
        baseHeight / 2,
        centerReactSide / 2 + outSize,
        1.501 * Math.PI,
        1.5 * Math.PI,
        false
      );
      ctx.lineWidth = outSize * 2;
      ctx.strokeStyle = "rgba(0,0,0,.7)";
      ctx.closePath();
      ctx.stroke();
    },
    onclose() {
      this.closeflag = 2;
      this.$emit("editFinished", this.closeflag);
    }
  }
};
</script>

<style lang="less">
.close {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 5rem;
  color: #fff !important;
}
.cutRange {
  width: 1200px;
  margin: 0 auto;
}
.cut-canvas {
  position: relative;
  margin: 0 auto;
}
.buttons {
  height: 80px;
  position: absolute;
  top: 78%;
  left: 50%;
}
</style>
