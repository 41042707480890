<template>
  <div class="whole">
    <Nav></Nav>
    <div class="registerAll">
      <div class="bindRole" v-if="pageStep == 1">
        <div class="step-role">1</div>
        <div class="step-rlink"></div>
        <div class="role-con">
          <div class="role-text">请选择你要注册的角色</div>
          <div class="role">
            <div class="role-type">
              <el-radio-group v-model="userType">
                <el-radio :label="1">上传用户</el-radio>
                <el-radio :label="31">下载用户</el-radio>
                <el-radio :label="53">昭觉上传用户</el-radio>
                <el-radio :label="56">视觉红安上传用户</el-radio>
              </el-radio-group>
            </div>
            <div class="role-down" v-if="userType == '1'">
              <el-radio-group v-model="daili" class="dailipower" @change="onChangeExclusive">
                <el-radio :label="3">独家代理</el-radio>
                <el-radio :label="4">一般代理</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="link">
            <el-button @click="onGoNext">下一步</el-button>
          </div>
        </div>
      </div>
      <div class="bindMobile" v-if="pageStep == 2">
        <!-- <div class="bindMobile"> -->
        <div class="step-one">2</div>
        <div class="step-link"></div>
        <div class="mobile-con">
          <div class="shouji">
            <span>手机号：</span>
            <el-input v-model="mobileInput" placeholder="请输入手机号" clearable @blur="onsetMobile"></el-input>
          </div>
          <div class="input-line">
            <span>图形验证码：</span>
            <el-input v-model="imgIcode" placeholder="请输入图形验证码" clearable></el-input>
            <div class="code-img">
              <img :src="icodeImage_URL" @click="onLoad" />
            </div>
          </div>
          <div class="mobileback">
            <span>手机验证码：</span>
            <el-input v-model="smsCode" placeholder="请输入验证码" clearable></el-input>
            <el-button @click="onCaptcha" v-if="requestSMS">获取验证码</el-button>
            <el-button v-else>{{ requestSMSTimeout }}秒后重发</el-button>
          </div>
          <div class="step">
            <el-button @click="onNext">下一步</el-button>
          </div>
        </div>
      </div>
      <div class="bindName" v-if="pageStep == 3">
        <!-- <div class="bindName"> -->
        <div class="step-two">3</div>
        <div class="step-tlink"></div>
        <div class="name-con">
          <div class="tip">(*为必填项)</div>
          <div class="cname">
            <span>登录名(必须是字母或数字及其组合4位以上*)：</span>
            <el-input v-model="loginName" v-bind:class="{ redBorder: warnings.loginName }" placeholder="请输入内容" @blur="onlogoName" clearable></el-input>
            <span style="color: red" v-if="warnings.loginName">(登录名不正确)</span>
          </div>
          <div class="realName">
            <span>真实姓名(*)：</span>
            <el-input v-model="chnName" placeholder="请输入内容" clearable></el-input>
          </div>

          <div class="address">
            <span>通讯地址(*)：</span>
            <el-input v-model="extra.address" placeholder="请输入内容" clearable></el-input>
          </div>
          <div class="postCode">
            <span>邮编(6位数组成*)：</span>
            <el-input v-bind:class="{ redBorder: warnings.postCode }" v-model="extra.postCode" placeholder="请输入编码" @blur="onpostCode" clearable></el-input>
            <span style="color: red" v-if="warnings.postCode">(邮编格式不正确)</span>
          </div>
          <div class="company">
            <span>工作单位(*)：</span>
            <el-input v-model="extra.company" placeholder="请输入工作单位" clearable></el-input>
          </div>
          <div class="zhiwu">
            <span>职务(*)：</span>
            <el-input v-model="extra.head" placeholder="请输入职务" clearable></el-input>
          </div>
          <div class="comment" v-if="userType == 1">
            <span>个人简介(100至150字*):</span>
            <el-input type="textarea" :rows="8" placeholder="请输入内容" v-model="extra.comment" @input="handleCommentChange" @blur="handlefinallychange"> </el-input>
            <i class="currenrsize">(当前字数：{{ commmentLength }})</i>
          </div>
          <div class="email">
            <span>邮箱(*)：</span>
            <el-input v-model="extra.email" v-bind:class="{ redBorder: warnings.email }" placeholder="请输入邮箱" @blur="onSendEmail" clearable></el-input>
            <span style="color: red" v-if="warnings.email">(邮箱格式不合法)</span>
          </div>
          <div class="qq" v-if="userType == '1'">
            <span>常用QQ(*)：</span>
            <el-input v-model="extra.qq" v-bind:class="{ redBorder: warnings.qq }" placeholder="请输入qq" @blur="onSendQQ" clearable></el-input>
            <span style="color: red" v-if="warnings.qq">(qq格式不正确)</span>
          </div>
          <div class="weixin" v-if="userType == '1'">
            <span>常用微信(字母开头*)：</span>
            <el-input v-model="extra.wx" v-bind:class="{ redBorder: warnings.wx }" placeholder="请输入微信号" @blur="onweixin" clearable></el-input>
            <span style="color: red" v-if="warnings.wx">(微信格式不正确)</span>
          </div>
          <div class="Idcard">
            <span>身份证号(18位数组成*)：</span>
            <span class="red">{{ extra.idNo == 'undefined' || extra.idNo == '' ? '未填写' : extra.idNo }}</span>
            <!-- <el-input v-model="IdCard" v-bind:class="{ redBorder: idcardShow }" placeholder="请输入内容" @blur="onSendIdcard" clearable></el-input> -->
            <!-- <span style="color: red" v-if="idcardShow">(身份证格式不正确)</span> -->
          </div>
          <div class="Idcardfront">
            <span>身份证正面(*)：</span>
            <div class="border">
              <el-upload
                ref="upload"
                class="avatar-uploader"
                accept=".jpg, .jpeg, .JPG, .JPEG"
                :action="idFrontImage"
                :with-credentials="true"
                :show-file-list="false"
                :on-success="onSuccessFront"
                :before-upload="beforeAvatarUpload"
              >
                <div v-if="idCardUploadURL" :style="{ backgroundImage: `url(` + idCardUploadURL + `)` }" class="avatar"></div>
                <div v-else class="el-icon-plus avatar-uploader-icon"></div>
              </el-upload>
            </div>
            <div class="cutFront" @click="onCutIdCard('front')">剪切身份证正面</div>
          </div>
          <div class="Idcardback">
            <span>身份证反面(*)：</span>
            <div class="border">
              <el-upload
                class="avatar-uploader"
                accept=".jpg, .jpeg, .JPG, .JPEG"
                :action="headImage"
                :show-file-list="false"
                :with-credentials="true"
                :on-success="onSuccessBack"
                :before-upload="beforeAvatarUpload"
              >
                <div v-if="idImgURLBack" :style="{ backgroundImage: `url(` + idImgURLBack + `)` }" class="avatar"></div>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div class="cutBack" @click="onCutIdCard('back')">剪切身份证反面</div>
          </div>
          <!-- <div class="telephone">
            <div class="tel-name">固定电话(主号码6位以上)：</div>
            <div class="area">
              <div class="area-name">区号:</div>
              <el-input v-model="telephone.areaCode" @blur="onareaCode" clearable></el-input>
            </div>
            <div class="tel-main">
              <div class="main-name">主号码:</div>
              <el-input v-bind:class="{ redBorder: warnings.maintelephone }" v-model="telephone.maintelephone" @blur="ontelephone" clearable placeholder="请输入主号码"></el-input>
            </div>
            <div class="tel-fen">
              <div class="fen-name">分机号:</div>
              <el-input v-model="telephone.fentelephone" clearable></el-input>
            </div>
          </div>

          <div class="bankaccont" v-if="userType == 1">
            <span>银行账户(16或19位等*)：</span>
            <el-input v-model="extra.bankAcc" v-bind:class="{ redBorder: warnings.bankAcc }" placeholder="请输入银行账户" clearable></el-input>
            <span style="color: red" v-if="warnings.bankAcc">(银行账户格式不正确)</span>
          </div>
          <div class="bank" v-if="userType == 1">
            <span>开户银行(与上述银行卡对应开户行*)：</span>
            <el-input v-model="extra.bank" placeholder="请输入开户行" clearable></el-input>
          </div>

          <div class="vip" v-if="userType == '1'">
            <span>何级摄影家协会会员(选填)：</span>
            <el-select v-model="extra.sheying" placeholder="请选择">
              <el-option v-for="item in sheyingList" :key="item" :label="item" :value="item"> </el-option>
            </el-select>
          </div>
          <div class="xinwen" v-if="userType == '1'">
            <span>何级新闻摄影学会会员(选填)：</span>
            <el-select v-model="extra.xinwen" placeholder="请选择">
              <el-option v-for="item in xinwenList" :key="item" :label="item" :value="item"> </el-option>
            </el-select>
          </div>
          <div class="sheji" v-if="userType == '1'">
            <span>何级设计师协会会员(选填)：</span>
            <el-select v-model="extra.sheji" placeholder="请选择">
              <el-option v-for="item in shejiList" :key="item" :label="item" :value="item"> </el-option>
            </el-select>
          </div>
          <div class="reward" v-if="userType == '1'">
            <span>获得过最高级别的专业奖(选填)：</span>
            <el-select v-model="extra.topReward" placeholder="请选择">
              <el-option v-for="item in rewardList" :key="item" :label="item" :value="item"> </el-option>
            </el-select>
          </div> -->
          <!-- <div class="representative" v-if="userType == 1">
            <div class="representative-one">
              <div class="representative-left">代表作品1(*):</div>
              <div class="representative-right">
                <div class="uploadTop">
                  <el-upload
                    ref="upload"
                    class="avatar-uploader"
                    accept=".jpg, .jpeg, .JPG, .JPEG"
                    :action="headImage"
                    :show-file-list="false"
                    :with-credentials="true"
                    :on-success="onSuccessRepresentative"
                    :before-upload="beforeAvatarUpload"
                    >上传代表作</el-upload
                  >
                </div>
                <div class="topPics">
                  <div class="top-con" v-for="(k, topIndex) in extra.topProducesList" :key="k + 'top_' + topIndex">
                    <div class="top-del el-icon-close" @click="ondelTopPics(topIndex)"></div>
                    <div class="top-img" :style="{ backgroundImage: `url(` + k.imgURL + `)` }"></div>
                    <div class="introl">
                      <span>作品介绍：</span>
                      <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="k.text"> </el-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="photo" v-if="userType == '1'">
            <div class="text">上传用户头像(*)：</div>
            <div class="border">
              <el-upload
                class="avatar-uploader"
                accept=".jpg, .jpeg, .JPG, .JPEG"
                :action="headImage"
                :show-file-list="false"
                :with-credentials="true"
                :on-success="onGetheadImage"
                :before-upload="beforeAvatarUpload"
              >
                <div class="showimg" v-if="headImageUploadURL" :style="{ backgroundImage: `url(` + headImageUploadURL + `)` }"></div>
                <div v-else class="el-icon-plus avatar-uploader-icon"></div>
              </el-upload>
            </div>
            <div class="cutPic" @click="onCutHead">剪切头像</div>
          </div>
          <div class="pw">
            <span>输入密码(密码应至少6位，包含大写字母至少2个，小写字母至少2个，必须有数字，且必须有特殊符号*)：</span>
            <el-input v-model="loginPassword" placeholder="请输入内容" show-password @blur="onpassword" clearable></el-input>
          </div>
          <div class="resetpw">
            <span>确认密码(与上述密码一致*)：</span>
            <el-input v-model="repw" placeholder="请输入内容" show-password clearable></el-input>
          </div>
          <div class="btnsure">
            <el-button @click="onEnsure" type="success">完成注册</el-button>
          </div>
        </div>
      </div>
    </div>

    <Bottom></Bottom>

    <cutPic class="cutCanvs" v-if="cutshadow" @editFinished="editFinished" :imgInfo="cutImg" @wheel.prevent="doNothing"> </cutPic>

    <cutCard class="cutCardCanvs" v-if="cutCardShow" @editFinishedCard="editFinishedCard" :idCardInfo="cutCard"> </cutCard>
  </div>
</template>

<script>
import RDUtils from '@/api/RDUtils'
import RD from '@/api/RD'
import SHA1 from 'js-sha1'
import cutPic from '@/views/cutHead.vue'
import cutCard from '@/views/cutIdCard.vue'
import Nav from '@/components/nav.vue'
import videoNav from '@/components/videoNav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom, videoNav, cutPic, cutCard },
  data: function () {
    return {
      smsCode: '',
      mobileInput: '',
      loginName: '',
      chnName: '',
      loginPassword: '',
      repw: '', // 重复密码
      extra: {
        address: '',
        bank: '',
        bankAcc: '',
        comment: '',
        company: '',
        email: '',
        exclusive: true,
        head: '',
        headImage: '',
        idBack: '',
        idFront: '',
        idNo: '',
        postCode: '',
        qq: '',
        sheji: '',
        sheying: '',
        telephone: '',
        topProducesList: [],
        topReward: '',
        wx: '',
        xinwen: '',
      },
      downloadUse: {
        newsPaper: '',
        newWork: '',
        weChat: '',
        client: '',
        zazhi: '',
        book: '',
        other: '',
      },
      telephone: {
        areaCode: '', // 固定电话区号
        maintelephone: '', // 固定电话的主号码
        fentelephone: '', // 分机号
      },
      // identifyingCode: '', // 输入的验证码
      // returnIdentifyingcode: '', // 手机返回来的验证码
      // input: '',
      // loginName: '',
      // realName: '',
      // IdCard: '', // 身份证号
      // IdCardFront: '', // 正面
      // IdCardBack: '', // 反面
      // password: '',
      // address: '',
      // company: '',
      // email: '',
      // postCode: '',
      // head: '',
      // repw: '',
      // reward: '', // 奖项
      // telephone: '',
      // sheji: '',
      // xinwen: '',
      // sheying: '',
      // bankAccont: '',
      // bank: '',
      // comment: '', // 个人简介
      // zhiwu: '',
      // weixin: '',
      // qq: '',
      // newsPaper: '',
      // newWork: '',
      // weChat: '',
      // client: '',
      // zazhi: '',
      // book: '',
      // other: '',

      pageStep: 1,
      userType: 1,
      idFrontImage: '',
      idCardUploadURL: '', // 上传身份证正面的地址
      idImgURLBack: '', // 上传身份证背面地址
      headImageUploadURL: '', // 上传头像的路径

      idImageURL: '', //本地身份证
      headImageURL: '', //本地头像

      sheyingList: ['国家级', '省级', '区市级'],
      xinwenList: ['国家级', '省级', '区市级'],
      shejiList: ['国家级', '省级', '区市级'],
      rewardList: ['国家级', '省级', '区市级'],
      daili: 3, // 注册的代理权限 代理权没有1,2何来3,4
      exclusive: false, //为啥这里你就用了boolean，而代理权没用
      navFlag: 1,
      requestSMS: true, // 验证码的显示
      requestSMSTimeout: 50,
      cutshadow: false, // 切图的显示
      cutCardShow: false, // 切身份证的显示
      cutImg: {}, // 切头像上传的数据
      cutCard: {}, // 切身份证
      warnings: {
        //post,email,qq,wx,telephone,bankAcc,idNo,loginName,password
      },
      //如果email有问题，就warnings.email = true，注意使用vue set，重置就直接warnings = {}
      // postShow: false, // 编码不合法时的提示
      // emailShow: false, // 邮箱不合法时的提示
      // qqShow: false, // qq不合法的提示
      // weixinShow: false, //
      // telephoneShow: false, //
      // bankAccShow: false, //
      // idcardShow: false, //
      // loginNameShow: false, //
      warnings: {
        email: false,
        qq: false,
        postCode: false,
        wx: false,
        bankAcc: false,
        maintelephone: false,
        loginName: false,
      },
      commmentLength: 0,
      icodeImage_URL: '',
      imgIcode: '',
    }
  },
  created() {
    var thiz = this
    thiz.headImage = RD.user().uploadPicUnregisted_URL()
    thiz.idFrontImage = RD.user().unregistID_URL()
    thiz.icodeImage_URL = RD.user().icodeImage_URL() + '?_' + new Date().getTime()
    if (this.$route.query.id == 1) {
      thiz.navFlag = 1
    } else {
      thiz.navFlag = 2
    }
    if (thiz.$route.query.type == 'buy') {
      thiz.userType = 2
    }
  },
  methods: {
    doNothing() {},
    //onGoPage1 onGoPage2 onGoPage3
    onGoNext() {
      this.pageStep = 2
    },
    gotoScroll(e) {
      var scorllheight = e.srcElement.scrollHeight - 200
      if (e.srcElement.scrollTop + e.srcElement.offsetHeight >= scorllheight) {
        console.log('到底了')
        this.xieyiBtnShow = true
      }
    },
    onLoad() {
      var thiz = this
      thiz.icodeImage_URL = RD.user().icodeImage_URL() + '?' + new Date().getTime()
    },
    // 是否是独家代理用户
    onChangeExclusive(value) {
      var thiz = this
      if (thiz.daili == 3) {
        thiz.exclusive = true
      } else {
        thiz.exclusive = false
      }
    },
    // 验证邮箱
    onSendEmail() {
      var thiz = this
      var regEmail = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/
      if (thiz.extra.email != '' && !regEmail.test(thiz.extra.email)) {
        this.$message.error({
          message: '邮箱格式不正确',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'email', true)
      } else {
        thiz.$set(thiz.warnings, 'email', false)
      }
    },
    // 验证QQ
    onSendQQ() {
      var thiz = this
      var regqq = /^[1-9][0-9]{4,10}$/
      if (thiz.extra.qq != '' && !regqq.test(thiz.extra.qq)) {
        this.$message.error({
          message: 'qq格式不正确',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'qq', true)
        return
      } else {
        thiz.$set(thiz.warnings, 'qq', false)
      }
    },
    // 验证微信
    onweixin() {
      var thiz = this
      var regweiixn = /^[a-zA-Z][a-zA-Z\d_-]{5,19}$/
      if (thiz.extra.wx != '' && !regweiixn.test(thiz.extra.wx)) {
        this.$message.error({
          message: '微信格式不正确',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'wx', true)
        return
      } else {
        thiz.$set(thiz.warnings, 'wx', false)
      }
    },
    // 区号
    onareaCode() {
      var thiz = this
      if (isNaN(parseInt(thiz.areaCode)) === true) {
        this.$message.error({
          message: '请输入数字区号',
          type: 'warning',
        })
        thiz.areaCode = ''
        return
      }
    },
    // 固定电话
    ontelephone() {
      var thiz = this
      var telmain = /^([0-9]{1})(\d{5,})$/
      if (thiz.telephone.maintelephone != '' && !telmain.test(thiz.telephone.maintelephone)) {
        thiz.$message.error({
          message: '请至少输入6位及以上数字号码',
          type: 'warning',
        })
        thiz.telephoneShow = true
        thiz.$set(thiz.warnings, 'maintelephone', true)
      } else {
        thiz.$set(thiz.warnings, 'maintelephone', false)
      }
    },
    // 验证银行账户
    onSendBank() {
      var thiz = this
      var regBank = /^([1-9]{1})(\d{11,18})$/
      if (thiz.bankAccont != '' && !regBank.test(thiz.bankAccont)) {
        this.$message.error({
          message: '银行账户格式不正确',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'bankAcc', true)

        return
      } else {
        thiz.$set(thiz.warnings, 'bankAcc', false)
      }
    },
    // 身份证号验证
    onSendIdcard() {
      var thiz = this
      var regIdcard = /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/
      if (thiz.IdCard != '' && !regIdcard.test(thiz.IdCard)) {
        this.$message.error({
          message: '身份证格式不正确',
          type: 'warning',
        })

        return
      } else {
        thiz.$set(thiz.warnings, 'idNo', false)
      }
    },
    // 验证手机号
    onsetMobile() {
      var thiz = this
      var mobile = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (thiz.mobileInput != '' && !mobile.test(thiz.mobileInput)) {
        thiz.$message('手机号不合法, 请重新填写')
      }
    },
    // 获取验证码
    onCaptcha() {
      var thiz = this
      if (!thiz.mobileInput) {
        this.$alert('手机号不能为空', '提示', {
          confirmButtonText: '确定',
          showClose: false,
          type: 'warning',
          callback: (action) => {},
        })
        return
      }
      if (!thiz.imgIcode) {
        this.$alert('图形验证码不能为空', '提示', {
          confirmButtonText: '确定',
          showClose: false,
          type: 'warning',
          callback: (action) => {},
        })
        return
      }
      RD.user()
        .requestSMSForRegister(thiz.imgIcode, thiz.mobileInput)
        .then(() => {
          thiz.requestSMS = false
          setInterval(() => {
            thiz.requestSMSTimeout--
            if (thiz.requestSMSTimeout == 0) {
              thiz.requestSMS = true
              thiz.requestSMSTimeout = 50
            }
          }, 1000)
          thiz.$message('手机发送验证码成功，请注意查收')
        })
    },
    // 下一步
    onNext() {
      var thiz = this
      if (thiz.mobileInput == '' || thiz.mobileInput == undefined) {
        thiz.$message('请先填写手机号')
        return
      }
      if (thiz.smsCode == '' || thiz.smsCode == undefined) {
        thiz.$message('请先填写手机验证码')
        return
      }
      RD.user()
        .confirmCodeForRegist(thiz.smsCode)
        .then(() => {
          thiz.pageStep = 3
        })
    },
    // 正则验证登录名
    onlogoName() {
      var thiz = this
      var name = /^[a-zA-Z0-9_-]{4,16}$/
      if (thiz.loginName != '' && !name.test(thiz.loginName)) {
        thiz.$message.error({
          message: '登录名不合法, 请重新填写',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'loginName', true)
        return
      } else {
        thiz.$set(thiz.warnings, 'loginName', false)
      }
    },
    // 邮编
    onpostCode() {
      var thiz = this
      var code = /^(0[1-7]|1[0-356]|2[0-7]|3[0-6]|4[0-7]|5[1-7]|6[1-7]|7[0-5]|8[013-6])\d{4}$/
      if (thiz.extra.postCode != '' && !code.test(thiz.extra.postCode)) {
        thiz.$message.error({
          message: '邮编不合法, 请重新填写',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'postCode', true)
      } else {
        thiz.$set(thiz.warnings, 'postCode', false)
      }
    },
    // 验证密码
    onpassword() {
      var thiz = this
      if (thiz.loginPassword != '' && !RDUtils.checkStrongPassword(thiz.loginPassword)) {
        this.$alert('新密码应至少6位，包含大写字母至少2个，小写字母至少2个，必须有数字，且必须有特殊符号', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          callback: (action) => {},
        })
        return
      }
    },
    // 确认
    async onEnsure() {
      var thiz = this
      var regEmail = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/
      if (thiz.extra.email != '' && !regEmail.test(thiz.extra.email)) {
        this.$message.error({
          message: '邮箱格式不正确',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'email', true)
        return
      } else {
        thiz.$set(thiz.warnings, 'email', false)
      }

      var code = /^(0[1-7]|1[0-356]|2[0-7]|3[0-6]|4[0-7]|5[1-7]|6[1-7]|7[0-5]|8[013-6])\d{4}$/
      if (thiz.extra.postCode != '' && !code.test(thiz.extra.postCode)) {
        thiz.$message.error({
          message: '邮编不合法, 请重新填写',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'postCode', true)
        return
      } else {
        thiz.$set(thiz.warnings, 'postCode', false)
      }

      var name = /^[a-zA-Z0-9_-]{4,16}$/
      if (thiz.loginName != '' && !name.test(thiz.loginName)) {
        thiz.$message.error({
          message: '登录名不合法, 请重新填写',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'loginName', true)

        return
      } else {
        thiz.$set(thiz.warnings, 'loginName', false)
      }

      var mobile = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (thiz.mobileInput != '' && !mobile.test(thiz.mobileInput)) {
        thiz.$message('手机号不合法, 请重新填写')
        return
      }

      // var regIdcard = /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/
      // if (thiz.extra.idNo != '' && !regIdcard.test(thiz.extra.idNo)) {
      //   this.$message.error({
      //     message: '身份证格式不正确',
      //     type: 'warning',
      //   })
      //   thiz.idcardShow = true
      //   return
      // } else {
      //   thiz.idcardShow = false
      // }

      if (thiz.loginPassword != '' && !RDUtils.checkStrongPassword(thiz.loginPassword)) {
        this.$alert('新密码应至少6位，包含大写字母至少2个，小写字母至少2个，必须有数字，且必须有特殊符号', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          callback: (action) => {},
        })
        return
      }

      var regweiixn = /^[a-zA-Z][a-zA-Z\d_-]{5,19}$/
      if (thiz.extra.wx != '' && !regweiixn.test(thiz.extra.wx)) {
        this.$message.error({
          message: '微信格式不正确',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'wx', true)
        return
      } else {
        thiz.$set(thiz.warnings, 'wx', false)
      }

      var regqq = /^[1-9][0-9]{4,10}$/
      if (thiz.extra.qq != '' && !regqq.test(thiz.extra.qq)) {
        this.$message.error({
          message: 'qq格式不正确',
          type: 'warning',
        })
        thiz.$set(thiz.warnings, 'qq', true)
        return
      } else {
        thiz.$set(thiz.warnings, 'qq', false)
      }

      // if (thiz.telephone.areaCode != '' && isNaN(parseInt(thiz.telephone.areaCode)) === true) {
      //   this.$message.error({
      //     message: '请输入数字区号',
      //     type: 'warning',
      //   })
      //   thiz.telephone.areaCode = ''
      //   return
      // }

      // var telmain = /^([0-9]{1})(\d{5,})$/
      // if (thiz.telephone.maintelephone != '' && !telmain.test(thiz.telephone.maintelephone)) {
      //   thiz.$message.error({
      //     message: '请至少输入6位及以上数字号码',
      //     type: 'warning',
      //   })
      //   thiz.$set(thiz.warnings, 'maintelephone', true)
      // } else {
      //   thiz.$set(thiz.warnings, 'maintelephone', false)
      // }

      // var areatel = ''
      // if (!thiz.telephone.areaCode) {
      //   areatel = ''
      // } else {
      //   areatel = thiz.telephone.areaCode + '-'
      // }

      // var maintel = ''
      // if (!thiz.telephone.maintelephone) {
      //   maintel = ''
      // } else {
      //   maintel = thiz.telephone.maintelephone + '-'
      // }

      thiz.extra.telephone = ''
      if (!thiz.loginName) {
        thiz.$message('登录名不能为空')
        return
      }
      if (!thiz.chnName) {
        thiz.$message('真实姓名不能为空')
        return
      }

      if (!thiz.extra.idNo) {
        thiz.$message('身份证号不能为空,请检查身份证正面是否上传正确')
        return
      }

      // if (thiz.headImage == '' || thiz.headImage == undefined) {
      //   thiz.$message('请上传身份证正面')
      //   return
      // }

      if (!thiz.extra.address) {
        thiz.$message('地址不能为空')
        return
      }
      if (!thiz.extra.postCode) {
        thiz.$message('邮编不能为空')
        return
      }
      if (!thiz.extra.email) {
        thiz.$message('邮箱不能为空')
        return
      }

      if (thiz.extra.company == '' || thiz.extra.company == undefined) {
        thiz.$message('工作单位不能为空')
        return
      }

      if (thiz.userType == 1) {
        if (!thiz.extra.qq) {
          thiz.$message('qq不能为空')
          return
        }

        if (thiz.extra.wx == '' || thiz.extra.wx == undefined) {
          thiz.$message('微信不能为空')
          return
        }
        // if (thiz.extra.bankAcc == '' || thiz.extra.bankAcc == undefined) {
        //   thiz.$message('银行账户不能为空')
        //   return
        // }
        // if (thiz.extra.bank == '' || thiz.extra.bank == undefined) {
        //   thiz.$message('开户行不能为空')
        //   return
        // }
        if (thiz.extra.comment == '' || thiz.extra.comment == undefined) {
          thiz.$message('个人简介不能为空')
          return
        }
        if (thiz.extra.comment.length > 150 || thiz.extra.comment.length < 100) {
          thiz.$message('个人简介字数不符合要求')
          return
        }
      }

      if (thiz.extra.idFront == '' || thiz.extra.idFront == undefined) {
        thiz.$message('身份证正面不能为空')
        return
      }

      if (thiz.extra.idBack == '' || thiz.extra.idBack == undefined) {
        thiz.$message('身份证反面不能为空')
        return
      }

      if (thiz.extra.head == '' || thiz.extra.head == undefined) {
        thiz.$message('职务不能为空')
        return
      }

      var checkname = await RD.user().checkChnName(thiz.loginName)
      if (checkname) {
        thiz.pageStep = 3
        thiz.registed = false
      }

      var checkloginName = RD.user().checkLoginName(thiz.chnName)
      if (checkloginName) {
        thiz.pageStep = 3
        thiz.registed = false
      }

      console.log('topicsarr', thiz.extra.topProducesList)

      if (thiz.loginPassword != thiz.repw) {
        thiz.$message('两次输入的密码不一致，请重新输入')
        return
      }
      if (!thiz.loginPassword) {
        thiz.$message.error({ message: '密码不能为空', type: 'warning' })
        return
      }

      if (thiz.userType != 31) {
        if (thiz.extra.headImageg == '' || thiz.extra.headImage == undefined) {
          thiz.$message.error({ message: '头像不能为空', type: 'warning' })
          return
        }

        console.log('register', thiz.loginName, SHA1(thiz.loginPassword), thiz.chnName, thiz.extra, thiz.userType)

        RD.user()
          .registerUploader(thiz.loginName, SHA1(thiz.loginPassword), thiz.chnName, thiz.extra, thiz.userType)
          .then(() => {
            thiz.$message('注册上传者角色成功,等待审批后方可上传')
            this.$router.push('/index.html')
          })
          .catch((error) => {
            thiz.$message(error)
          })
      } else {
        RD.user()
          .registerDownloader(thiz.loginName, SHA1(thiz.loginPassword), thiz.chnName, thiz.extra, thiz.userType)
          .then((info) => {
            console.log('kl', info)
            thiz.$message('注册下载者角色成功,等待审批后方可上传')
            thiz.$router.push('/index.html')
          })
      }
    },
    // 上传头像成功后的处理
    onGetheadImage(res, file) {
      console.log('head', res)
      var thiz = this
      if (res.code == 0) {
        thiz.headImageUploadURL = URL.createObjectURL(file.raw)
        thiz.extra.headImage = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传身份证正面成功后的处理
    onSuccessFront(res, file) {
      console.log('zheng', res)
      if (res.code == 0) {
        this.extra.idNo = res.data.idNo
        this.idCardUploadURL = URL.createObjectURL(file.raw)
        this.extra.idFront = res.data.uuid
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传身份证反面成功后的处理
    onSuccessBack(res, file) {
      var thiz = this
      console.log('fan', res)
      if (res.code == 0) {
        thiz.idImgURLBack = URL.createObjectURL(file.raw)
        thiz.extra.idBack = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传代表作
    onSuccessRepresentative(res, file) {
      var thiz = this
      console.log('res', res)
      if (res.code == 0) {
        var top = {}
        top.imgURL = URL.createObjectURL(file.raw)
        top.uuid = res.data
        thiz.extra.topProducesList.push(top)
        console.log('topics', thiz.extra.topProducesList)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 删除代表作
    ondelTopPics(index) {
      console.log('ss', index)
      thiz.extra.topProducesList.splice(index, 1)
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    // 切头像
    onCutHead() {
      if (this.headImageUploadURL) {
        this.cutshadow = true
        this.cutImg.src = RD.user().unregistedImageGet_URL(this.extra.headImage) + '?_=' + new Date().getTime()
        this.cutImg.width = window.innerWidth * 0.5
        this.cutImg.height = window.innerHeight * 0.5
      } else {
        this.$message('请先上传要剪切的头像')
      }
    },
    // 打开切头像的弹框
    editFinished(value, arrs) {
      console.log('cut', value)
      console.log('cutarrs', arrs)

      if (value == 2) {
        this.cutshadow = false
      }

      if (arrs != undefined) {
        RD.user()
          .uploadPicUnregistedCut(this.extra.headImage, arrs.x1, arrs.y1, arrs.x2, arrs.y2)
          .then(() => {
            this.$message('剪切图片成功')
            this.headImageUploadURL = RD.user().unregistedImageGet_URL(this.extra.headImage) + '?_=' + new Date().getTime()
          })
      }
    },
    // 剪切身份证
    onCutIdCard(type) {
      if (type == 'front') {
        if (this.idCardUploadURL) {
          this.cutCardShow = true
          this.cutCard.src = RD.user().unregistedImageGet_URL(this.extra.idFront) + '?_=' + new Date().getTime()
          this.cutCard.width = window.innerWidth * 0.5
          this.cutCard.height = window.innerHeight * 0.5
          this.cutCard.type = 'front'
        } else {
          this.$message('请先上传身份证正面')
        }
      } else {
        if (this.idImgURLBack) {
          this.cutCardShow = true
          this.cutCard.src = RD.user().unregistedImageGet_URL(this.extra.idBack) + '?_=' + new Date().getTime()
          this.cutCard.width = window.innerWidth * 0.5
          this.cutCard.height = window.innerHeight * 0.5
          this.cutCard.type = 'back'
        } else {
          this.$message('请先上传身份证反面')
        }
      }

      console.log('shs', this.cutCard)
    },
    // 切身份证的返回
    editFinishedCard(value, arrsCard, cutType) {
      console.log('cut', value)
      console.log('cutarrs', arrsCard)

      if (value == 2) {
        this.cutCardShow = false
      }

      if (arrsCard != undefined) {
        if (cutType == 1) {
          RD.user()
            .uploadPicUnregistedCut(this.extra.idFront, arrsCard.x1, arrsCard.y1, arrsCard.x2, arrsCard.y2)
            .then(() => {
              this.$message('剪切身份证成功')
              this.idCardUploadURL = RD.user().unregistedImageGet_URL(this.extra.idFront) + '?_=' + new Date().getTime()
            })
        } else {
          console.log('back')
          RD.user()
            .uploadPicUnregistedCut(this.extra.idBack, arrsCard.x1, arrsCard.y1, arrsCard.x2, arrsCard.y2)
            .then(() => {
              this.$message('剪切身份证成功')
              this.idImgURLBack = RD.user().unregistedImageGet_URL(this.extra.idBack) + '?_=' + new Date().getTime()
            })
        }
      }
    },
    // 检测简介的文字数量
    handleCommentChange() {
      var thiz = this
      thiz.commmentLength = this.extra.comment.length
    },
    // 失去焦点验证
    handlefinallychange() {
      var thiz = this
      if (thiz.commmentLength >= 150 || thiz.commmentLength <= 100) {
        this.$alert('最多不能超过150, 最少不能少于100', '提示', {
          confirmButtonText: '确定',
          callback: (action) => {},
        })
        return
      }
    },
  },
}
</script>

<style scoped lang="less">
.whole {
  height: auto;
}
.cutCanvs {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0rem;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}
.cutCardCanvs {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0rem;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}

.registerAll {
  min-height: calc(100vh - 25.7rem);
  width: 120rem;
  margin: 0 auto;
  .bindRole {
    height: auto;
    min-height: 58.5rem;
    margin: 0 auto;
    position: relative;
    font-size: 1.5rem;
    .step-role {
      position: absolute;
      top: 2rem;
      left: 0rem;
      width: 6rem;
      height: 5.2rem;
      border: 0.1rem solid rgb(105, 241, 189);
      border-radius: 50%;
      font-size: 3rem;
      padding-top: 0.8rem;
      background-color: rgb(105, 241, 189);
      color: #fff;
    }
    .step-rlink {
      position: absolute;
      top: 6.1rem;
      left: 3rem;
      border-left: 0.1rem solid rgb(105, 241, 189);
      height: 100%;
      width: 0.1rem;
    }
    .role-con {
      margin: 0 auto;
      width: 56rem;
      height: auto;
      .role-text {
        height: 6rem;
        padding-top: 2rem;
        font-size: 2rem;
      }
      .role {
        padding-top: 2rem;
        height: 8.8rem;
        text-align: left;
        .role-type {
          height: 4rem;
          .el-radio-group {
            text-align: left;
            .el-radio {
              margin-right: 3rem;
              font-size: 1.3rem;
            }
          }
        }
        .dailipower {
          margin-top: 2rem;
          margin-left: 0rem;
        }
      }
      .link {
        display: inline-block;
        position: relative;
        height: 8rem;
        width: 100%;
        .el-button {
          position: absolute;
          right: 5rem;
          top: 40%;
        }
      }
    }
  }
  .bindMobile {
    height: auto;
    margin: 0 auto;
    position: relative;
    .step-one {
      position: absolute;
      top: 2rem;
      left: 0rem;
      width: 6rem;
      height: 5.2rem;
      border: 0.1rem solid rgb(105, 241, 189);
      border-radius: 50%;
      font-size: 3rem;
      padding-top: 0.8rem;
      background-color: rgb(105, 241, 189);
      color: #fff;
    }
    .step-link {
      position: absolute;
      top: 6.1rem;
      left: 3rem;
      border-left: 0.1rem solid rgb(105, 241, 189);
      height: 100%;
      min-height: 58.3rem;
      width: 0.1rem;
    }
    .mobile-con {
      margin: 0 auto;
      width: 60rem;
      position: relative;
      font-size: 1.6rem;
      .shouji {
        text-align: left;
        // margin-top: 5vh;
        padding-top: 50px;
        span {
          width: 10rem;
          display: inline-block;
          text-align: justify;
          text-align-last: justify;
        }
        .el-input {
          display: inline-block;
          width: 30rem;
        }
      }
      .input-line {
        height: 7rem;
        line-height: 50px;
        text-align: left;
        display: flex;
        span {
          display: inline-block;
          width: 100px;
          text-align: left;
          font-size: 1.6rem;
          line-height: 7rem;
        }
        .el-input {
          width: 22rem;
          line-height: 7rem;
        }
        .el-textarea {
          width: 84rem;
        }
        i {
          font-style: normal;
          font-size: 16px;
          margin-left: 10px;
          line-height: 30px;
        }
        .code-img {
          line-height: 7rem;
          img {
            margin-top: 1.2rem;
            margin-left: 1rem;
          }
        }
      }
      .mobileback {
        text-align: left;
        span {
          width: 10rem;
          display: inline-block;
          text-align: justify;
          text-align-last: justify;
        }
        .el-input {
          display: inline-block;
          width: 27rem;
        }
        .el-button {
          margin-left: 1.5rem;
        }
      }
      .step {
        margin-right: 2rem;
        text-align: right;
        .el-button {
          margin-top: 2.5rem;
          margin-right: 2rem;
        }
      }
    }
  }
  .bindName {
    height: auto;
    border-bottom: 0.1rem solid #ccc;
    position: relative;
    top: 0rem;
    left: 0rem;
    .step-two {
      position: absolute;
      top: 0rem;
      left: 0rem;
      width: 6rem;
      height: 5.2rem;
      border-radius: 50%;
      font-size: 3rem;
      padding-top: 0.8rem;
      background-color: rgb(105, 241, 189);
      color: #fff;
      border: 0.1rem solid rgb(105, 241, 189);
    }
    .step-tlink {
      position: absolute;
      top: 6.1rem;
      left: 3rem;
      border-left: 0.1rem solid rgb(105, 241, 189);
      height: 95.5%;
      width: 0.1rem;
    }
    .name-con {
      margin-left: 28rem;
      width: 77.5rem;
      font-size: 1.5rem;
      .tip {
        text-align: left;
        height: 4rem;
        line-height: 4rem;
        color: red;
      }
      .btnsure {
        height: 6rem;
        margin-top: 3rem;
        text-align: right;
        margin-right: 20rem;
        margin-bottom: 0.5rem;
      }
      .cname {
        text-align: left;
        .el-input {
          display: inline-block;
          width: 22rem;
        }
      }
      .tel {
        text-align: left;
        margin-top: 3vh;
      }
      .longname,
      .address,
      .email,
      .Idcard,
      .mobile,
      .fax,
      .company,
      .postCode,
      .realName,
      .qq,
      .weixin,
      .zhiwu,
      .bankaccont,
      .head {
        text-align: left;
        margin-top: 1.2rem;
        box-sizing: border-box;
        height: 4rem;
        line-height: 4rem;
        span {
          display: inline-block;
          width: 23.1rem;
        }
        .el-input {
          display: inline-block;
          width: 29rem;
          margin-right: 1rem;
        }
        .redBorder {
          border: 0.2rem solid red;
        }
      }
      .Idcard {
        .red {
          color: red;
        }
      }
      .comment {
        text-align: left;
        margin-top: 1.2rem;
        box-sizing: border-box;
        height: 23rem;
        line-height: 4rem;
        span {
          display: inline-block;
          width: 23.1rem;
        }
        .el-textarea {
          display: inline-block;
          width: 60rem;
          margin-right: 1rem;
        }
        .currenrsize {
          height: 5rem;
          font-style: normal;
        }
      }
      .representative {
        height: auto;
        margin-top: 2rem;
        .representative-one {
          height: auto;
          display: flex;
          .representative-left {
            width: 23.1rem;
            text-align: left;
          }
          .representative-right {
            height: auto;
            width: 50rem;
            display: flex;
            flex-shrink: 0;
            .uploadTop {
              width: 11.5rem;
              height: 3rem;
              line-height: 3rem;
              border: 0.1rem solid #ccc;
              margin-right: 2rem;
            }
            .topPics {
              height: auto;
              width: 30.4rem;
              .top-con {
                margin-bottom: 2rem;
                position: relative;
                .top-del {
                  position: absolute;
                  right: -4rem;
                  top: 0rem;
                  width: 4rem;
                  height: 3rem;
                  font-size: 2rem;
                  padding-top: 0.8rem;
                  border-radius: 50%;
                  background-color: rgba(0, 0, 0, 0.3);
                }
                .top-del:hover {
                  color: red;
                }
                .top-img {
                  width: 100%;
                  height: 15rem;
                  background: no-repeat 50% / cover;
                }
                .introl {
                  height: 12rem;
                  width: 100%;
                }
              }
            }
          }
        }
      }
      .telephone {
        text-align: left;
        margin-top: 3.3rem;
        box-sizing: border-box;
        height: 5rem;
        line-height: 5rem;
        display: flex;
        .tel-name {
          width: 23.1rem;
        }
        .area {
          display: flex;
          .area-name {
            width: 4.7rem;
          }
          .el-input {
            width: 10rem;
            margin-right: 1rem;
          }
        }
        .tel-main {
          display: flex;
          .main-name {
            width: 6.5rem;
          }
          .el-input {
            width: 15.5rem;
            margin-right: 1rem;
          }
          .redBorder {
            border: 0.2rem solid red;
          }
        }
        .tel-fen {
          display: flex;
          .fen-name {
            width: 6.5rem;
          }
          .el-input {
            width: 9rem;
          }
        }
      }
      .bank {
        text-align: left;
        margin-top: 1.2rem;
        span {
          display: inline-block;
          width: 31rem;
        }
        .el-input {
          display: inline-block;
          width: 29rem;
        }
      }
      .el-button {
        margin-top: 0.5rem;
        margin-left: 35rem;
      }
      .Idcardfront {
        text-align: left;
        margin-top: 2rem;
        height: 18rem;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        span {
          width: 23.1rem;
        }
        .border {
          height: 17.8rem;
          width: 33.3rem;
          border: 0.1rem solid #ccc;
          .avatar-uploader-icon {
            font-size: 2.8rem;
            color: #8c939d;
            width: 100%;
            height: 100%;
            line-height: 20rem;
            text-align: center;
          }
          ::v-deep.el-upload {
            width: 33.3rem;
            height: 17.8rem;
            .avatar {
              width: 100%;
              height: 100%;
              background: no-repeat 50% / contain;
            }
          }
        }
        .cutFront {
          width: 13rem;
          padding-left: 0.5rem;
          height: 4rem;
          line-height: 4rem;
          border: 0.1rem solid #ccc;
          border-radius: 0.5rem;
          margin-left: 1rem;
          margin-top: 7.5rem;
          cursor: pointer;
        }
      }
      .Idcardback {
        text-align: left;
        margin-top: 2rem;
        height: 18rem;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        span {
          width: 23.1rem;
        }
        .border {
          height: 17.8rem;
          width: 33.3rem;
          border: 0.1rem solid #ccc;
          .avatar-uploader-icon {
            font-size: 2.8rem;
            color: #8c939d;
            width: 100%;
            height: 100%;
            line-height: 20rem;
            text-align: center;
          }
          ::v-deep.el-upload {
            width: 33.3rem;
            height: 17.8rem;
            .avatar {
              width: 100%;
              height: 100%;
              background: no-repeat 50% / contain;
            }
          }
        }
        .cutBack {
          width: 13rem;
          padding-left: 0.5rem;
          height: 4rem;
          line-height: 4rem;
          border: 0.1rem solid #ccc;
          border-radius: 0.5rem;
          margin-left: 1rem;
          margin-top: 7.5rem;
          cursor: pointer;
        }
      }
      .photo {
        margin-top: 2rem;
        height: 20rem;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        .text {
          width: 20.8rem;
          text-align: left;
        }
        .border {
          width: 20rem;
          height: 20rem;
          border-radius: 10rem;
          border: 0.1rem solid #ccc;
          overflow: hidden;
          .avatar-uploader-icon {
            font-size: 2.8rem;
            color: #8c939d;
            width: 100%;
            height: 100%;
            line-height: 20rem;
            text-align: center;
          }
          ::v-deep.el-upload {
            width: 100%;
            height: 100%;
            .showimg {
              width: 20rem;
              height: 20rem;
              background: no-repeat 50% / cover;
            }
          }
        }
        .cutPic {
          margin-left: 1rem;
          border-radius: 0.5rem;
          margin-top: 7.5rem;
          width: 10rem;
          height: 4rem;
          line-height: 4rem;
          border: 0.1rem solid #ccc;
          cursor: pointer;
        }
      }
      .pw,
      .resetpw {
        text-align: left;
        margin-top: 3.3rem;
        height: 5rem;
        line-height: 5remx;
        display: flex;
        span {
          width: 24.1rem;
        }
        .el-input {
          display: inline-block;
          width: 25rem;
          margin-right: 0.8rem;
          margin-top: -0.5rem;
          ::v-deep .el-input__suffix {
            height: 80%;
          }
        }
        .redBorder {
          border: 0.2rem solid red;
        }
      }
      .daibiao1,
      .daibiao2,
      .daibiao3 {
        margin-top: 2rem;
        height: 18rem;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        .text {
          width: 16rem;
          text-align: left;
        }
        .daibiaoPic {
          height: 10rem;
          margin-left: 1rem;
          width: 20rem;
          border: 0.1rem solid #ccc;
        }
      }
      .vip,
      .xinwen,
      .sheji,
      .reward,
      .daibiao1Text,
      .daibiao2Text,
      .daibiao3Text {
        text-align: left;
        margin-top: 1.2rem;
        span {
          display: inline-block;
          width: 27rem;
        }
        .el-input {
          width: 30rem;
        }
      }
    }
  }
  .bindPassWord {
    height: auto;
    border-bottom: 0.1rem solid #ccc;
    position: relative;
    top: 0rem;
    left: 0rem;
    .step-three {
      position: absolute;
      top: 0rem;
      left: 0rem;
      width: 6rem;
      height: 5.2rem;
      border-radius: 50%;
      font-size: 3rem;
      padding-top: 0.8rem;
      background-color: rgb(105, 241, 189);
      color: #fff;
      border: 0.1rem solid rgb(105, 241, 189);
    }
    .step-thlink {
      position: absolute;
      top: 6.1rem;
      left: 3rem;
      border-left: 0.1rem solid rgb(105, 241, 189);
      height: 75%;
      width: 0.1rem;
    }
    .password-con {
      margin: 0 auto;
      width: 36vw;
      .tel,
      .name {
        text-align: left;
        margin-top: 3vh;
        font-size: 1.3rem;
        span {
          margin-left: 1.5rem;
        }
      }

      .el-button {
        margin-top: -0.5rem;
        margin-left: 35rem;
      }
    }
  }
}
</style>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 0.1rem dashed #d9d9d9;
  border-radius: 0.6rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
</style>
